<template>
  <ValidationObserver ref="objects" v-slot="{ invalid }">
    <v-form @submit.prevent="resetPassword">
      <ValidationProvider name="Email" rules="required|email">
        <v-text-field
          label="Email"
          name="email"
          type="email"
          v-model="email"
          autocomplete="email"
          :error-messages="errors"
          slot-scope="{ errors }"
        />
      </ValidationProvider>
      <ValidationProvider name="password" rules="required|min:10|max:20">
        <v-text-field
          id="password"
          label="New Password"
          name="password"
          v-model="password"
          type="password"
          autocomplete="new-password"
          :error-messages="errors"
          slot-scope="{ errors }"
        />
      </ValidationProvider>
      <ValidationProvider name="Password confirmation" rules="required|confirmed:password">
        <v-text-field
          id="password_confirmation"
          label="Confirm Password"
          name="password_confirmation"
          v-model="password_confirmation"
          type="password"
          autocomplete="new-password"
          :error-messages="errors"
          slot-scope="{ errors }"
        />
      </ValidationProvider>
      <v-card-actions class="d-flex justify-space-between">
        <router-link to="/login" class="text-decoration-none text-uppercase"> Back </router-link>
        <v-btn type="submit" :disabled="invalid" color="primary">Send</v-btn>
      </v-card-actions>
    </v-form>
  </ValidationObserver>
</template>

<script>
// import { getError } from "@/utils/helpers";
import { extend, ValidationProvider, ValidationObserver } from 'vee-validate';
import { required, email, max, min, confirmed } from 'vee-validate/dist/rules';
import AuthService from '@/services/AuthService';

extend('required', required);
extend('email', email);
extend('max', max);
extend('min', min);
extend('confirmed', confirmed);

export default {
  name: 'ResetPasswordForm',
  components: {
    ValidationProvider,
    ValidationObserver
  },
  data() {
    return {
      email: '',
      password: '',
      password_confirmation: ''
    };
  },
  methods: {
    async resetPassword() {
      const payload = {
        email: this.email,
        password: this.password,
        password_confirmation: this.password_confirmation,
        token: this.$route.query.token
      };
      try {
        this.$store.dispatch('ui/loading', true);
        const response = await AuthService.resetPassword(payload);
        if (response) {
          this.$store.dispatch('ui/snackbarSuccess', 'Reset password complete.');
        } else {
          const error = Error();
          error.name = 'Error with resetting password';
          throw error;
        }
      } catch (error) {
        this.$store.dispatch('ui/loading', false);
        this.$store.dispatch('ui/snackbarError', 'Unable to reset password.');
      }
      window.location.href = '/login';
    }
  }
};
</script>
