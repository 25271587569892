<template>
  <v-main transition="false" fill-height style="background: #eee; height: 100vh" fluid>
    <v-container fill-height fluid>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="8" md="4">
          <v-card class="elevation-4">
            <v-toolbar color="primary" dark flat>
              <v-toolbar-title>Reset Password</v-toolbar-title>
              <v-spacer />
            </v-toolbar>
            <v-card-text>
              To continue to reset your password, enter your email address and password in the form below.
            </v-card-text>
            <v-card-text>
              <ResetPasswordForm />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import ResetPasswordForm from '@/components/ResetPasswordForm';

export default {
  name: 'ResetPasswordView',
  components: {
    ResetPasswordForm
  }
};
</script>
