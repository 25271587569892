<template>
  <ValidationObserver ref="objects" v-slot="{ invalid }">
    <v-form @submit.prevent="forgotPassword">
      <ValidationProvider name="Email" rules="required|email">
        <v-text-field
          label="Email"
          name="email"
          prepend-icon="mdi-account-circle"
          type="email"
          v-model="email"
          autocomplete="email"
          :error-messages="errors"
          slot-scope="{ errors }"
        />
      </ValidationProvider>
      <v-card-actions class="d-flex justify-space-between">
        <router-link to="/login" class="text-decoration-none text-uppercase"> Back </router-link>
        <v-btn type="submit" :disabled="invalid" color="primary">Send</v-btn>
      </v-card-actions>
    </v-form>
  </ValidationObserver>
</template>

<script>
// import { getError } from "@/utils/helpers";
import { extend, ValidationProvider, ValidationObserver } from 'vee-validate';
import { required, email } from 'vee-validate/dist/rules';
import AuthService from '@/services/AuthService';

extend('required', required);
extend('email', email);

export default {
  name: 'ForgottenPasswordForm',
  components: {
    ValidationProvider,
    ValidationObserver
  },
  data() {
    return {
      email: ''
    };
  },
  methods: {
    async forgotPassword() {
      const payload = {
        email: this.email
      };
      try {
        this.$store.dispatch('ui/loading', true);
        const response = await AuthService.forgotPassword(payload);
        if (response) {
          await this.$store.dispatch('ui/snackbarSuccess', 'Reset password email sent.');
        } else {
          const error = Error();
          error.name = 'Send forgotten password';
          throw error;
        }
      } catch (error) {
        this.$store.dispatch('ui/loading', false);
        this.$store.dispatch('ui/snackbarError', 'Unable to send password.');
      }
      window.location.href = '/login';
    }
  }
};
</script>
