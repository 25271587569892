import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VForm } from 'vuetify/lib/components/VForm';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"objects",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c(VForm,{on:{"submit":function($event){$event.preventDefault();return _vm.resetPassword.apply(null, arguments)}}},[_c('ValidationProvider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c(VTextField,{attrs:{"label":"Email","name":"email","type":"email","autocomplete":"email","error-messages":errors},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"password","rules":"required|min:10|max:20"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c(VTextField,{attrs:{"id":"password","label":"New Password","name":"password","type":"password","autocomplete":"new-password","error-messages":errors},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Password confirmation","rules":"required|confirmed:password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c(VTextField,{attrs:{"id":"password_confirmation","label":"Confirm Password","name":"password_confirmation","type":"password","autocomplete":"new-password","error-messages":errors},model:{value:(_vm.password_confirmation),callback:function ($$v) {_vm.password_confirmation=$$v},expression:"password_confirmation"}})}}],null,true)}),_c(VCardActions,{staticClass:"d-flex justify-space-between"},[_c('router-link',{staticClass:"text-decoration-none text-uppercase",attrs:{"to":"/login"}},[_vm._v(" Back ")]),_c(VBtn,{attrs:{"type":"submit","disabled":invalid,"color":"primary"}},[_vm._v("Send")])],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }