<template>
  <v-main transition="false" fill-height style="background: #eee; height: 100vh" fluid>
    <v-container fill-height fluid>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="8" md="4">
          <v-card class="elevation-4">
            <v-toolbar color="primary" dark flat>
              <v-toolbar-title>Forgotten Password</v-toolbar-title>
              <v-spacer />
            </v-toolbar>
            <v-card-text>
              If you have forgotten your password, please enter your email address and click the send button. If your
              email is valid you will be sent an email with instructions.
            </v-card-text>
            <v-card-text>
              <ForgottenPasswordForm />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import ForgottenPasswordForm from '@/components/ForgottenPasswordForm';

export default {
  name: 'ForgotPasswordView',
  components: {
    ForgottenPasswordForm
  }
};
</script>
